.App {
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    rgb(3, 77, 114) 1%,
    rgb(113, 49, 99) 100%
  );
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.notes-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.note-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

button.active {
  background-color: #ff4444;
  color: white;
}

button.inactive {
  background-color: #4caf50;
  color: white;
}

h1 {
  text-align: center;
  color: #1f1f1f;
  margin-bottom: 2rem;
  font-size: 24px;
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

/* Стили для сообщений */
.success-message,
.error-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  animation: fadeIn 0.3s ease-in;
  max-width: 90%;
  width: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.success-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  max-width: 80%;
  font-weight: 500;
  animation: fadeIn 0.3s ease-out forwards;
}

.error-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  max-width: 80%;
  font-weight: 500;
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Адаптивность */
@media (max-width: 576px) {
  .App {
    padding: 10px;
  }

  h1 {
    font-size: 20px;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .success-message,
  .error-message {
    font-size: 14px;
    padding: 10px;
    top: 10px;
  }

  .react-select__menu {
    width: 90vw !important;
    max-width: 90vw !important;
    left: 5vw !important;
    right: 5vw !important;
  }
}

/* Глобальные стили для react-select */
.react-select__menu,
.css-26l3qy-menu,
.css-1nmdiq5-menu,
div[class*="-menu"] {
  z-index: 9999 !important;
  position: absolute !important;
  background-color: white !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  margin-top: 2px !important;
  width: 100% !important;
  left: 0 !important;
  right: auto !important;
  top: 100% !important;
}

/* Портал для меню */
#portal-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  pointer-events: none;
  z-index: 9999;
}

#portal-root > div {
  pointer-events: auto;
}
