/* Удаляем дублирующиеся стили для формы, так как они уже есть в App.css */
.order-form {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  padding: 2px;
  border-radius: 12px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2px;
  position: relative;
  z-index: 1;
  width: 100%;
}

/* 
 * Расстояние между полями ввода контролируется через margin-bottom в .form-group
 * По умолчанию это 1.5rem для десктопов и 1rem для мобильных устройств
 * При необходимости изменить расстояние между полями, нужно изменить значение margin-bottom
 */
.form-group {
  margin-bottom: 2rem;
}

.form-group label {
  display: block;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #333;
  top: 10px;
}

/* Стили для контейнера блоков заказов */
.flex {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

@media (min-width: 768px) {
  .flex {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .flex .additional-order-item {
    width: calc(50% - 10px);
  }
}

/* Стили для плавающих лейблов */
.floating-label-container {
  position: relative;
  margin-bottom: 10px;
}

.floating-label-container input {
  width: 100%;
  height: 45px;
  padding: 15px 10px 0 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  background-color: white;
  transition: border-color 0.3s;
}

.floating-label-container label {
  position: absolute;
  left: 10px;
  top: 14px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease;
  margin-bottom: 0;
  font-weight: normal;
}

.floating-label-container input:focus {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.floating-label-container input:focus + label,
.floating-label-container input:not(:placeholder-shown) + label,
.floating-label-container label.active {
  top: 4px;
  left: 5px;
  font-size: 11px;
  color: #8bcf8e;
  font-weight: 500;
}

.floating-label-container input::placeholder {
  color: transparent;
}

/* Стили для плавающих лейблов в селектах */
.select-container {
  position: relative;
  margin-bottom: 10px;
}

.select-container .select-label {
  position: absolute;
  left: 10px;
  top: 14px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease;
  margin-bottom: 0;
  font-weight: normal;
  z-index: 2;
}

.select-container .select-label.active,
.select-container.has-value .select-label {
  top: 4px;
  left: 5px;
  font-size: 11px;
  color: #8bcf8e;
  font-weight: 500;
}

.select-container .react-select__control {
  padding-top: 10px;
  min-height: 45px;
}

.select-container.has-value .react-select__placeholder,
.select-container.focused .react-select__placeholder {
  opacity: 0;
}

/* Корректировка стилей для отображения значения в селекте */
.select-container .react-select__value-container {
  padding-top: 10px;
}

.select-container.has-value .react-select__single-value,
.select-container.focused .react-select__single-value {
  top: 60%;
  transform: translateY(-60%);
}

/* Новые стили для телефонного ввода с плавающим лейблом */
.custom-phone-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  height: 45px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  padding-top: 0;
}

.custom-phone-input-container.with-floating-label {
  padding-top: 10px;
}

.custom-phone-input-container.with-floating-label .phone-label {
  position: absolute;
  left: 50px;
  top: 14px;
  font-size: 14px;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease;
  margin-bottom: 0;
  font-weight: normal;
}

.custom-phone-input-container.with-floating-label.focused .phone-label,
.custom-phone-input-container.with-floating-label.filled .phone-label {
  top: 2px;
  left: 5px;
  font-size: 11px;
  color: #8bcf8e;
  font-weight: 500;
}

.phone-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 100%;
  background-color: #f5f5f5;
  border-right: 1px solid #ddd;
  color: #333;
  font-weight: 500;
  font-size: 10px;
  user-select: none;
}

.custom-phone-input {
  flex: 1;
  height: 45px;
  border: none;
  padding: 10px 15px 10px 10px;
  font-size: 10px;
  letter-spacing: 0.5px;
  font-family: inherit;
  outline: none;
  background-color: white;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
}

.custom-phone-input:focus {
  outline: none;
}

.custom-phone-input::placeholder {
  color: #999;
  opacity: 1;
  letter-spacing: 0.5px;
}

.custom-phone-input-container:focus-within {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

/* Стиль для плавающего лейбла в обычных инпутах */
.custom-input-container {
  position: relative;
}

.custom-input-container label {
  position: absolute;
  left: 10px;
  top: 14px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease;
  margin-bottom: 0;
  font-weight: normal;
}

.custom-input-container input:focus + label,
.custom-input-container input:not(:placeholder-shown) + label {
  top: 4px;
  left: 5px;
  font-size: 11px;
  color: #8bcf8e;
  font-weight: 500;
}

.custom-input {
  width: 100%;
  height: 45px;
  padding: 15px 10px 0 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  background-color: white;
  transition: border-color 0.3s;
}

.custom-input:focus {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.custom-input::placeholder {
  color: transparent;
}

/* Удаляем старые стили компонента PhoneInput */
.phone-container,
.phone-input,
.react-tel-input {
  /* Скрываем устаревшие стили */
  display: none !important;
}

@media (max-width: 480px) {
  .custom-phone-input {
    font-size: 12px;
  }
}

/* Кнопка отправки */
.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #45a049;
}

/* Сообщения об ошибках и информация */
.phone-error {
  color: #ff3b30;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
  text-align: left;
}

.phone-info {
  color: #007aff;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
  text-align: left;
}

/* Адаптивность */
@media (max-width: 840px) {
  .order-form {
    max-width: 95%;
    padding: 2rem;
    margin: 1rem;
  }
}

@media (max-width: 480px) {
  .order-form {
    max-width: 100%;
    padding: 2px;
    margin: 1px;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-group label {
    font-size: 14px;
    top: 14px;
  }

  .submit-button {
    padding: 12px;
    font-size: 16px;
  }

  /* Обновленный единый стиль для телефонного поля ввода */
  .custom-phone-input {
    padding: 8px 15px 8px 10px !important;
  }

  /* Общие стили шрифта для полей ввода */
  select,
  input,
  textarea,
  .custom-phone-input,
  .react-select__input input {
    font-size: 10px !important;
  }
}

/* Отключение автозаполнения */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Скрываем историю и автозаполнение в браузере */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* Скрываем стрелки в input type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Скрываем автозаполнение в Chrome */
input:-webkit-autofill {
  -webkit-background-clip: text;
}

/* Скрываем выпадающий список автозаполнения */
input::-webkit-calendar-picker-indicator {
  display: none;
}

/* Скрываем выпадающий список автозаполнения в Firefox */
input:-moz-list-bullet {
  display: none;
}

/* Скрываем выпадающий список автозаполнения в Edge */
input::-ms-clear {
  display: none;
}

/* Скрываем выпадающий список автозаполнения в IE */
input::-ms-reveal {
  display: none;
}

/* Скрываем выпадающий список автозаполнения в Safari */
input::-webkit-contacts-auto-fill-button {
  display: none !important;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* Дополнительные стили для Select компонента */
.css-26l3qy-menu {
  z-index: 9999 !important;
}

/* Улучшенные стили для выпадающих списков react-select */
.react-select__menu {
  z-index: 9999 !important;
  position: absolute !important;
  background-color: white !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  margin-top: 2px !important;
  overflow: hidden !important;
  animation: fadeInSelect 0.2s ease-in-out !important;
  width: 100% !important;
  left: 0 !important;
}

@keyframes fadeInSelect {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.react-select__menu-portal {
  z-index: 9999 !important;
}

.react-select__menu-list {
  padding: 0 !important;
  max-height: 250px !important;
  scrollbar-width: thin !important;
}

.react-select__menu-list::-webkit-scrollbar {
  width: 6px !important;
}

.react-select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background: #c1c1c1 !important;
  border-radius: 3px !important;
}

.react-select__option {
  padding: 10px 12px !important;
  cursor: pointer !important;
  transition: background-color 0.2s ease !important;
}

.react-select__option--is-focused {
  background-color: #f0f0f0 !important;
}

.react-select__option--is-selected {
  background-color: #2684ff !important;
  color: white !important;
}

/* Улучшенные стили для контрола селекта */
.react-select__control {
  min-height: 45px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  background-color: white !important;
  box-shadow: none !important;
  transition: all 0.2s ease !important;
  position: relative;
  z-index: 1;
}

.react-select__control:hover {
  border-color: #b3b3b3 !important;
}

.react-select__control--is-focused {
  border-color: #2684ff !important;
  box-shadow: 0 0 0 1px #2684ff !important;
}

.react-select__value-container {
  padding: 2px 8px !important;
}

.react-select__placeholder {
  color: #999 !important;
}

.react-select__single-value {
  color: #333 !important;
}

.react-select__indicator {
  padding: 6px !important;
  color: #999 !important;
}

.react-select__indicator:hover {
  color: #666 !important;
}

.react-select__indicator-separator {
  background-color: #ddd !important;
}

/* Стили для портала меню */
#portal-root {
  position: relative;
  z-index: 9999;
}

/* Дополнительные стили для исправления проблемы с выпадающими списками */
.react-select__control {
  position: relative;
  z-index: 1;
}

.react-select__value-container {
  z-index: 1;
}

.react-select__indicators {
  z-index: 1;
}

/* Обеспечиваем правильное отображение выпадающих списков на мобильных устройствах */
@media (max-width: 480px) {
  .react-select__menu {
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
    right: auto !important;
  }
}

/* Дополнительные стили для полного отключения истории в селектах */
.react-select__input input {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -webkit-user-modify: read-write !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-text-fill-color: currentcolor !important;
  background-color: transparent !important;
  background-image: none !important;
  color: inherit !important;
  transition: none !important;
}

.react-select__input input:-webkit-autofill,
.react-select__input input:-webkit-autofill:hover,
.react-select__input input:-webkit-autofill:focus,
.react-select__input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: currentcolor !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: transparent !important;
  caret-color: currentcolor !important;
}

/* Скрываем выпадающий список автозаполнения в браузерах */
input::-webkit-calendar-picker-indicator,
input::-webkit-list-button,
input::-webkit-clear-button,
input::-webkit-inner-spin-button,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration,
input::-webkit-details-marker {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
}

/* Отключаем автозаполнение в браузерах */
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  background-color: transparent !important;
  color: inherit !important;
}

/* Скрываем выпадающие списки автозаполнения в Chrome */
input:-webkit-autofill {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: currentcolor !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

/* Отключаем автозаполнение в Firefox */
input:-moz-autofill,
input:-moz-autofill-preview {
  filter: none !important;
  background-color: transparent !important;
}

/* Отключаем автозаполнение в Edge и IE */
input:-ms-input-placeholder {
  opacity: 1 !important;
}

/* Отключаем автозаполнение в Safari */
input:autofill {
  background-color: transparent !important;
  -webkit-text-fill-color: currentcolor !important;
}

/* Скрываем кнопки автозаполнения */
::-webkit-credentials-auto-fill-button {
  visibility: hidden !important;
  pointer-events: none !important;
  position: absolute !important;
  right: 0 !important;
}

/* Скрываем выпадающие списки datalist */
datalist {
  display: none !important;
}

/* Стили для полей ручного ввода */
.custom-input {
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: inherit;
  transition: border-color 0.2s ease;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  margin-bottom: 5px;
}

.custom-input:focus {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
  outline: none;
}

.custom-input::placeholder {
  color: #999;
  opacity: 1;
}

.custom-input-container {
  margin-top: 10px;
  margin-bottom: 15px;
  animation: fadeIn 0.3s ease-in;
}

.input-error {
  color: #ff3b30;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
}

/* Отключаем автозаполнение для всех элементов формы */
form[autocomplete="off"] * {
  background-image: none !important;
}

/* Стили для сообщения об ошибке формата */
.format-error {
  position: absolute;
  bottom: -30px;
  left: 0;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Стили для контейнера комментария к телефону */
.phone-comment-container {
  margin-top: 10px;
  width: 100%;
}

.phone-comment-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  transition: border-color 0.3s;
}

.phone-comment-input::placeholder {
  font-size: 12px;
  color: #999;
}

.phone-comment-input:focus {
  border-color: #7c6dde;
  outline: none;
}

/* Добавляем стили для дополнительных заявок */
.additional-order-item {
  margin-top: 2px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  margin-bottom: 15px;
}

.additional-order-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.additional-order-header {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
}

.additional-order-image-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 6px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.additional-order-image-container:hover {
  transform: scale(1.05);
}

.additional-order-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.additional-order-title {
  flex: 1;
}

.additional-order-title h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.additional-order-title p {
  margin: 4px 0 0;
  font-size: 12px;
  color: #666;
}

.additional-order-toggle {
  flex-shrink: 0;
  align-self: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-button {
  padding: 2px 4px;
  border: none;
  border-radius: 4px;
  background-color: #19cf95;
  color: white;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toggle-button.active {
  background-color: #ff6b6b;
  color: white;
}

/* Стили для иконки лупы */
.image-zoom-icon {
  position: absolute;
  bottom: 2px;
  right: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.additional-order-image-container:hover .image-zoom-icon {
  opacity: 1;
}

/* Стили для модального окна просмотра изображений */
.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease;
}

.image-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  animation: zoomIn 0.3s ease;
}

.image-modal-img {
  display: block;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.image-modal-caption {
  padding: 10px;
  text-align: center;
  font-weight: 500;
  color: #333;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
}

.image-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  color: #333;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  line-height: 1;
  transition: background-color 0.2s ease;
}

.image-modal-close:hover {
  background-color: #f0f0f0;
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Адаптация для мобильных устройств */
@media (max-width: 576px) {
  .image-modal-content {
    width: 95%;
  }

  .image-modal-img {
    max-height: 70vh;
  }

  .image-modal-close {
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    font-size: 20px;
  }

  .additional-order-image-container {
    width: 40px;
    height: 40px;
  }

  .image-zoom-icon {
    width: 14px;
    height: 14px;
    font-size: 8px;
  }
}

/* Стили для стрелки */
.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease;
  font-size: 10px;
}

.arrow-icon.right {
  transform: rotate(0deg);
}

.arrow-icon.down {
  transform: rotate(90deg);
}

/* Стили для блока с дополнительной информацией */
.additional-info-block {
  background-color: #f0f7ff;
  border-radius: 6px;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-left: 3px solid #4a90e2;
  font-size: 12px;
  color: #333;
  animation: fadeIn 0.3s ease;
}

.additional-info-block p {
  margin: 5px 0;
  line-height: 1.4;
}

.additional-order-details {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px dashed #ddd;
}

/* Стили для строки с количеством и общим объемом */
.fryoil-order-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  flex-direction: row;
}

.fryoil-quantity-container {
  flex: 1;
  max-width: 75%;
}

/* Стили для блока общего объема */
.fryoil-total-volume {
  flex: 1;
  max-width: 25%;
}

.fryoil-total-volume label,
.fryoil-quantity-container label {
  display: block;
  margin-bottom: 1px;
  font-size: 10px;
  color: #333;
}

/* Стили для отображения объема */
.volume-display {
  width: 100%;
  padding: 1px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  font-size: 10px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.quantity-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 10px;
}

/* Для мобильных устройств */
@media (max-width: 576px) {
  .additional-order-header {
    flex-wrap: wrap;
    padding-right: 80px;
  }

  .additional-order-image-container {
    width: 40px;
    height: 40px;
  }

  .additional-order-title {
    flex-basis: calc(100% - 60px);
  }

  .additional-order-toggle {
    position: absolute;
    right: 0;
    top: 0;
    transform: none;
    width: auto;
  }

  .toggle-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .fryoil-order-row {
    flex-direction: row;
    gap: 10px;
  }

  .fryoil-quantity-container,
  .fryoil-total-volume {
    max-width: 50%;
    width: auto;
  }
}

/* Оптимизация для устройств с очень маленькими экранами */
@media (max-height: 740px) {
  .form-container {
    min-height: auto;
    padding: 10px;
  }

  .order-form {
    padding: 15px;
    margin: 10px 0;
  }

  .form-group {
    margin-bottom: 8px;
  }

  .form-group label {
    margin-bottom: 2px;
    font-size: 13px;
  }

  .custom-phone-input-container,
  .custom-phone-input,
  .custom-input,
  .react-select__control {
    height: 38px !important;
    min-height: 38px !important;
    margin-bottom: 5px;
  }

  .additional-order-item {
    margin-top: 10px;
    padding: 8px;
  }

  .additional-order-header {
    gap: 8px;
  }

  .additional-order-image-container {
    width: 30px;
    height: 30px;
  }

  .additional-order-title h3 {
    font-size: 14px;
  }

  .additional-order-title p {
    font-size: 10px;
    margin: 2px 0 0;
  }

  .toggle-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .additional-order-details {
    margin-top: 8px;
    padding-top: 8px;
  }

  .fryoil-quantity-container label,
  .fryoil-total-volume label {
    font-size: 11px;
    margin-bottom: 2px;
  }

  .quantity-input,
  .volume-display {
    padding: 6px;
    font-size: 11px;
  }
}

/* Еще более компактный вид для очень маленьких экранов */
@media (max-height: 640px) {
  .form-container {
    padding: 5px;
  }

  .order-form {
    padding: 10px;
    margin: 5px 0;
  }

  .form-group {
    margin-bottom: 5px;
  }

  .custom-phone-input-container,
  .custom-phone-input,
  .custom-input,
  .react-select__control {
    height: 40px !important;
    min-height: 40px !important;
  }

  /* Оптимизация выпадающих списков */
  .react-select__indicator {
    padding: 4px !important;
  }

  .react-select__value-container {
    padding: 0 6px !important;
  }

  .react-select__menu-list {
    max-height: 180px !important;
  }

  .react-select__option {
    padding: 6px 8px !important;
  }

  .react-select__placeholder,
  .react-select__single-value {
    font-size: 13px !important;
  }

  .additional-order-item {
    margin-top: 5px;
    padding: 5px;
  }

  .additional-order-header {
    align-items: flex-start;
  }

  .additional-order-image-container {
    width: 50px;
    height: 55px;
  }

  /* Скрываем описание на очень маленьких экранах */
  .additional-order-title p {
    display: none;
  }

  .submit-button {
    padding: 8px;
    font-size: 14px;
  }

  .fryoil-order-row {
    margin-top: 1px;
  }

  .fryoil-quantity-container label,
  .fryoil-total-volume label {
    font-size: 10px;
    white-space: nowrap;
  }

  .quantity-input,
  .volume-display {
    padding: 3px;
    font-size: 14px;
    min-height: auto;
  }
}

/* Крайне компактный вид для самых маленьких экранов */
@media (max-height: 568px) {
  .order-form {
    padding: 5px;
    margin: 0;
    box-shadow: none;
    border: none;
  }

  .form-group {
    margin-bottom: 3px;
  }

  /* Делаем кнопки доп. заявок более компактными */
  .additional-order-item {
    padding: 3px;
    margin-top: 3px;
  }

  .additional-order-header {
    gap: 4px;
  }

  .additional-order-title h3 {
    font-size: 12px;
  }

  .toggle-button {
    padding: 4px 8px;
    font-size: 11px;
  }

  /* Минимальные отступы в полях ввода */
  .custom-phone-input-container,
  .custom-phone-input,
  .custom-input,
  .react-select__control {
    height: 35px !important;
    min-height: 35px !important;
  }

  /* Уменьшаем размер фонта для экономии вертикального пространства */
  .form-group label,
  .custom-input::placeholder,
  .custom-phone-input::placeholder,
  .react-select__placeholder,
  .react-select__single-value,
  .phone-prefix {
    font-size: 11px !important;
  }

  .fryoil-quantity-container label,
  .fryoil-total-volume label,
  .quantity-input,
  .volume-display {
    font-size: 10px;
    padding: 3px;
  }

  .fryoil-order-row {
    gap: 5px;
  }
}

.additional-order-title h3 {
  font-size: 14px;
}

.additional-order-title p {
  font-size: 10px;
  margin: 2px 0 0;
}

.toggle-button {
  padding: 6px 10px;
  font-size: 12px;
}

.additional-order-details {
  margin-top: 8px;
  padding-top: 8px;
}

.fryoil-quantity-container label {
  font-size: 12px;
  margin-bottom: 3px;
}

.quantity-input {
  padding: 6px;
  font-size: 13px;
}

.submit-button {
  padding: 10px;
  margin-top: 8px;
}

/* Уменьшаем размер элементов UFO и камеры */

/* Оптимизация для скроллинга на мобильных устройствах */
.mobile-optimized {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior: contain;
}

/* Оптимизация для iPhone SE и других очень маленьких устройств */
@media (max-height: 568px) and (max-width: 375px) {
  /* Полностью скрываем декоративные элементы */

  .form-container {
    padding: 1px;
    margin: 0;
  }

  .order-form {
    padding: 1px;
    margin: 0;
    box-shadow: none;
    border: none;
    background: rgba(255, 255, 255, 0.9);
  }

  /* Максимально компактные поля */
  .additional-order-item {
    padding: 2px;
    margin-top: 2px;
    border-width: 1px;
  }

  /* Минимальные размеры элементов */
  .custom-phone-input-container,
  .custom-phone-input,
  .custom-input,
  .react-select__control {
    height: 30px !important;
    min-height: 30px !important;
    font-size: 10px !important;
  }

  /* Уменьшаем размер всех текстовых элементов */
  .form-group label,
  .custom-input::placeholder,
  .custom-phone-input::placeholder,
  .react-select__placeholder,
  .react-select__single-value,
  .additional-order-title h3,
  .phone-prefix,
  .toggle-button,
  .submit-button {
    font-size: 10px !important;
  }
}

/* Глобальные стили для маленьких устройств */
body.small-device .form-container {
  min-height: auto;
  padding: 5px;
  margin: 0;
}

body.small-device .scene {
  transform: scale(0.7);
  margin: -15px 0;
}

body.small-device .camera {
  transform: scale(0.7);
  margin: -15px 0;
}

/* Для сверхмаленьких устройств */
body.tiny-device .scene,
body.tiny-device .ufoWrap {
  display: none;
}

body.tiny-device .camera {
  transform: scale(0.6);
  margin: -20px 0;
}

body.tiny-device .order-form-wrapper {
  padding-top: 0;
  margin-top: 0;
}

body.tiny-device .order-form {
  padding: 8px;
  margin: 0;
}

/* Важно: добавляем фиксированный размер шрифта для iOS устройств */
@supports (-webkit-touch-callout: none) {
  /* Стили только для iOS устройств */
  input,
  select,
  textarea,
  button {
    font-size: 16px !important; /* Предотвращает зум при фокусе в iOS */
  }

  /* Специально для телефонного поля ввода на iOS */
  .custom-phone-input {
    font-size: 16px !important; /* Предотвращает зум при фокусе на iOS */
    -webkit-text-size-adjust: 100%; /* Предотвращает автоматическое масштабирование */
  }

  /* Для сверхмаленьких iOS устройств дополнительно оптимизируем */
  body.tiny-device input,
  body.tiny-device select,
  body.tiny-device textarea,
  body.tiny-device button {
    font-size: 13px !important;
  }
}

/* Стили для кнопки "Подробнее" */
.info-toggle-button {
  background: none;
  border: none;
  color: #4a90e2;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 0;
  margin-top: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.info-toggle-button:hover {
  text-decoration: underline;
}

/* Добавляем псевдоэлемент для отображения подсказки под полем ввода телефона */
.custom-phone-input-container::after {
  content: "Начните с цифры 9";
  display: none;
  bottom: -1px;
  left: 1px; /* Позиционируем справа от префикса +7 */
  font-size: 10px;
  color: #666;
  opacity: 0.8;
}

@media (max-width: 576px) {
  .custom-phone-input-container::after {
    bottom: -9px;
    font-size: 8px;
    padding: 1px;
    height: 50px;
    left: 1px;
    display: none;
  }
}
