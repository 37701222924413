/* Modern CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Базовые стили для изображений и медиа */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Наследование шрифтов для форм */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаление анимаций для пользователей с настройкой reduced-motion */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Custom Properties */
:root {
  --color-primary: #3498db;
  --color-primary-dark: #2980b9;
  --color-secondary: #2c3e50;
  --color-danger: #e74c3c;
  --color-danger-dark: #c0392b;
  --color-success: #2ecc71;
  --color-success-dark: #27ae60;
  --color-warning: #f39c12;
  --color-warning-dark: #e67e22;
  --color-light: #f5f7fa;
  --color-dark: #333;
  --color-gray: #777;
  --color-gray-light: #ddd;

  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 2px 5px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 4px 10px rgba(0, 0, 0, 0.1);

  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;

  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --spacing-xl: 30px;
}

/* Utility Classes */
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}

.gap-sm {
  gap: var(--spacing-sm);
}
.gap-md {
  gap: var(--spacing-md);
}
.gap-lg {
  gap: var(--spacing-lg);
}

.mt-sm {
  margin-top: var(--spacing-sm);
}
.mt-md {
  margin-top: var(--spacing-md);
}
.mt-lg {
  margin-top: var(--spacing-lg);
}
.mb-sm {
  margin-bottom: var(--spacing-sm);
}
.mb-md {
  margin-bottom: var(--spacing-md);
}
.mb-lg {
  margin-bottom: var(--spacing-lg);
}

.p-sm {
  padding: var(--spacing-sm);
}
.p-md {
  padding: var(--spacing-md);
}
.p-lg {
  padding: var(--spacing-lg);
}

.rounded-sm {
  border-radius: var(--border-radius-sm);
}
.rounded-md {
  border-radius: var(--border-radius-md);
}
.rounded-lg {
  border-radius: var(--border-radius-lg);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}
.shadow-md {
  box-shadow: var(--shadow-md);
}
.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.text-primary {
  color: var(--color-primary);
}
.text-secondary {
  color: var(--color-secondary);
}
.text-danger {
  color: var(--color-danger);
}
.text-success {
  color: var(--color-success);
}
.text-warning {
  color: var(--color-warning);
}

.bg-primary {
  background-color: var(--color-primary);
}
.bg-secondary {
  background-color: var(--color-secondary);
}
.bg-danger {
  background-color: var(--color-danger);
}
.bg-success {
  background-color: var(--color-success);
}
.bg-warning {
  background-color: var(--color-warning);
}
.bg-light {
  background-color: var(--color-light);
}
.bg-white {
  background-color: white;
}

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.hidden {
  display: none;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}

/* Responsive utilities */
@media (min-width: 640px) {
  .sm\:flex {
    display: flex;
  }
  .sm\:hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex;
  }
  .md\:hidden {
    display: none;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }
  .lg\:hidden {
    display: none;
  }
}
