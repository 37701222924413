.verification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.verification-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.verification-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.verification-modal-header h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.verification-modal-body {
  padding: 20px;
}

.verification-modal-body p {
  margin-bottom: 20px;
  color: #555;
}

.verification-input-container {
  margin-bottom: 20px;
}

.verification-input-container input {
  width: 100%;
  padding: 12px 15px;
  font-size: 1.2rem;
  letter-spacing: 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
}

.verification-error {
  color: #e53935;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.verification-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.verify-button {
  padding: 12px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.verify-button:hover:not(:disabled) {
  background-color: #1976d2;
}

.verify-button:disabled {
  background-color: #bbdefb;
  cursor: not-allowed;
}

.resend-button {
  background: none;
  border: none;
  color: #2196f3;
  text-decoration: underline;
  padding: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.resend-button:disabled {
  color: #bdbdbd;
  cursor: not-allowed;
  text-decoration: none;
}

.resend-timer {
  color: #757575;
  font-size: 0.9rem;
  text-align: center;
}

@media (max-width: 480px) {
  .verification-modal {
    width: 95%;
  }
}
